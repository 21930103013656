<template>
<validation-observer
  ref="VForm"
>
  <b-form @submit.prevent="doSubmitArticle">
    <b-tabs v-if="mrValidation" v-model="tabIndex">
      <b-tab title="News Content">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <!-- <b-col lg="6">
                <b-form-group label-for="articleCat">
                  <template #label>Category<span class="text-danger mr5">*</span></template>
                  <v-select
                    id="articleCat" 
                    placeholder="Select Category"
                    v-model="row.ab_category"
                    :options="mrCategory"
                    :reduce="v => v.value"
                  />
                  <VValidate 
                    name="Category" 
                    v-model="row.ab_category" 
                    :rules="mrValidation.ab_category" 
                  />
                </b-form-group>
              </b-col> -->
              <b-col lg="9">
                <b-card no-body class="border">
                  <b-tabs card v-model="tabIndexContent">
                    <b-tab title="Content ID">
                      <b-form-group label-for="articleTitle">
                        <template #label>Title ID<span class="text-danger mr5">*</span></template>
                        <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" id="articleTitle" @input="watchTitle('id')" v-model="row.ab_title_id" placeholder="e.g Indonesia has many culture" />
                        <VValidate 
                          name="Title ID" 
                          v-model="row.ab_title_id" 
                          :rules="{...mrValidation.ab_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleSlugId">
                        <template #label>Slug ID<span class="text-danger mr5">*</span></template>
                        <b-form-input :disabled="row.ab_have_published == 'Y'" :formatter="e => String(e).slugify()" id="articleSlugId" v-model="row.ab_slug_id" />
                        <VValidate 
                          name="Slug ID" 
                          v-model="row.ab_slug_id" 
                          :rules="{...mrValidation.ab_slug_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleContent">
                        <template #label>Content ID<span class="text-danger mr5">*</span></template>
                        <CKEditor 
                          id="articleContent"
                          :value.sync="row.ab_content_id"
                          :customToolbar="itemsToolbar"
                        />
                        <VValidate 
                          name="Content ID" 
                          v-model="row.ab_content_id" 
                          :rules="mrValidation.ab_content_id" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleTags">
                        <template #label>Tags ID<span class="text-danger mr5">*</span></template>
                        <b-form-tags v-model="row.ab_tags_id" id="articleTags" remove-on-delete tag-variant="success" tag-class="text-white"/>
                        <VValidate 
                          name="Tags ID" 
                          :value="((row.ab_tags_id||[]).length?1:'')" 
                          :rules="{required: true}" 
                        />
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Content EN">
                      <b-form-group label-for="articleTitle">
                        <template #label>Title EN<span class="text-danger mr5">*</span></template>
                        <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" id="articleTitle" @input="watchTitle('en')" v-model="row.ab_title_en" placeholder="e.g Indonesia has many culture" />
                        <VValidate 
                          name="Title EN" 
                          v-model="row.ab_title_en" 
                          :rules="{...mrValidation.ab_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleSlugEn">
                        <template #label>Slug EN<span class="text-danger mr5">*</span></template>
                        <b-form-input :disabled="row.ab_have_published =='Y'" :formatter="e => String(e).slugify()" id="articleSlugEn" v-model="row.ab_slug_en" />
                        <VValidate 
                          name="Slug EN" 
                          v-model="row.ab_slug_en" 
                          :rules="{...mrValidation.ab_slug_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleContentEN">
                        <template #label>Content EN<span class="text-danger mr5">*</span></template>
                        <CKEditor 
                          id="articleContentEN"
                          :value.sync="row.ab_content_en"
                          :customToolbar="itemsToolbar"
                        />
                        <VValidate 
                          name="Content EN" 
                          v-model="row.ab_content_en" 
                          :rules="mrValidation.ab_content_en" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleTags">
                        <template #label>Tags EN<span class="text-danger mr5">*</span></template>
                        <b-form-tags v-model="row.ab_tags_en" id="articleTags" remove-on-delete tag-variant="success" tag-class="text-white"/>
                        <VValidate 
                          name="Tags EN" 
                          :value="((row.ab_tags_en||[]).length?1:'')" 
                          :rules="{required: true}" 
                        />
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Content JP">
                      <b-form-group label-for="articleTitle">
                        <template #label>Title JP<span class="text-danger mr5">*</span></template>
                        <b-form-input id="articleTitle" @input="watchTitle('jp')" v-model="row.ab_title_jp" placeholder="e.g インドネシアには多くの文化があります" />
                        <VValidate 
                          name="Title JP" 
                          v-model="row.ab_title_jp" 
                          :rules="mrValidation.ab_title_jp" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleSlugEn">
                        <template #label>Slug JP<span class="text-danger mr5">*</span></template>
                        <b-form-input :disabled="row.ab_have_published =='Y'" :formatter="e => String(e).slugify()" id="articleSlugEn" v-model="row.ab_slug_jp" />
                        <VValidate 
                          name="Slug JP" 
                          v-model="row.ab_slug_jp" 
                          :rules="mrValidation.ab_slug_jp" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleContent">
                        <template #label>Content JP<span class="text-danger mr5">*</span></template>
                        <CKEditor 
                          id="articleContent"
                          :value.sync="row.ab_content_jp"
                          :customToolbar="itemsToolbar"
                        />
                        <VValidate 
                          name="Content JP" 
                          v-model="row.ab_content_jp" 
                          :rules="mrValidation.ab_content_jp" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleTagsJP">
                        <template #label>Tags JP<span class="text-danger mr5">*</span></template>
                        <b-form-tags v-model="row.ab_tags_jp" id="articleTagsJP" remove-on-delete tag-variant="success" tag-class="text-white"/>
                        <VValidate 
                          name="Tags JP" 
                          :value="((row.ab_tags_jp||[]).length?1:'')" 
                          :rules="{required: true}" 
                        />
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>

              <b-col lg="3">
                <b-row>
                  <b-col lg="12" class="mb-2">
                    <b-form-group>
                      <template #label>Image <span class="text-danger ml5">*</span></template>
                      <Uploader v-model="row.ab_image" type="blog-content"/>
                      <VValidate 
                        name="Image" 
                        v-model="row.ab_image"
                        :rules="{required: true}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="mb-2">
                    <b-form-group label-for="articleAuthor">
                      <template #label>Author<span class="text-danger mr5">*</span></template>
                      <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar"
                        id="articleAuthor"
                        v-model="row.ab_author"
                        placeholder="e.g Administrator"
                      />
                      <VValidate 
                        name="Author" 
                        v-model="row.ab_author" 
                        :rules="{...mrValidation.ab_author, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="mb-2">
                    <b-form-group label-for="articlePubDate">
                      <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                      <div class="input-group">
                        <datepicker input-class="form-control transparent" v-model="row.ab_publish_date" :disabled="row.is_status_draft||false" placeholder="Select Publish Date"></datepicker>
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                        </div>
                      </div>

                      <VValidate 
                        name="Publish Date" 
                        v-model="row.ab_publish_date" 
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="h-200">
                    <b-form-group label-cols-md="3" label="Status">
                      <b-badge v-if="blogStatus=='P'" variant="success" class="post-block__badge">Published</b-badge>
                      <b-badge v-else-if="blogStatus=='D'" variant="info" class="post-block__badge">Draft</b-badge>
                      <b-badge v-else-if="blogStatus=='N'" variant="danger" class="post-block__badge">Unpublished</b-badge>
                    </b-form-group>

                    <b-col md=12>
                      <b-form-group label-for="status-publish">
                        <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                        <b-form-radio-group
                          id="status-publish"
                          v-model="row.ab_status"
                          :options="$parent.blogStatus"
                          name="status-publish"
                        ></b-form-radio-group>
                        <VValidate name="Status" v-model="row.ab_status"
                          :rules="'required'" />
                      </b-form-group>
                    </b-col>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="SEO Settings">
        <b-card no-body>
          <b-card-header>
            <b-card-title title-tag="h5">News SEO Settings</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-tabs>
              <b-tab title="ID">
                <b-card>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_title_id" />
                        <VValidate 
                          name="Meta Title ID" 
                          v-model="row.ab_seo_title_id" 
                          :rules="{...mrValidation.ab_seo_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Canonical ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Canonical berfungsi untuk menentukan halaman versi utama jika memiliki halaman dengan konten yang duplikat atau serupa.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_canonical_id" />
                        <VValidate 
                          name="Meta Canonical ID" 
                          v-model="row.ab_seo_canonical_id" 
                          :rules="{...mrValidation.ab_seo_canonical_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.ab_seo_desc_id" />
                        <VValidate 
                          name="Meta Desc ID" 
                          v-model="row.ab_seo_desc_id" 
                          :rules="{...mrValidation.ab_seo_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords ID
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.ab_seo_keywords_id" />
                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab title="EN">
                <b-card>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_title_en" />
                        <VValidate 
                          name="Meta Title EN" 
                          v-model="row.ab_seo_title_en" 
                          :rules="{...mrValidation.ab_seo_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Canonical EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Canonical serves to determine the main version of the page if it has pages with duplicate or similar content.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_canonical_en" />
                        <VValidate 
                          name="Meta Canonical EN" 
                          v-model="row.ab_seo_canonical_en" 
                          :rules="{...mrValidation.ab_seo_canonical_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.ab_seo_desc_en" />
                        <VValidate 
                          name="Meta Desc EN" 
                          v-model="row.ab_seo_desc_en" 
                          :rules="{...mrValidation.ab_seo_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords EN
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.ab_seo_keywords_en" />
                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab title="JP">
                <b-card>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title JP<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_title_jp" />
                        <VValidate 
                          name="Meta Title JP" 
                          v-model="row.ab_seo_title_jp" 
                          :rules="mrValidation.ab_seo_title_jp" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Canonical JP<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Canonical serves to determine the main version of the page if it has pages with duplicate or similar content.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ab_seo_canonical_jp" />
                        <VValidate 
                          name="Meta Canonical JP" 
                          v-model="row.ab_seo_canonical_jp" 
                          :rules="mrValidation.ab_seo_canonical_jp" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description JP<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.ab_seo_desc_jp" />
                        <VValidate 
                          name="Meta Desc JP" 
                          v-model="row.ab_seo_desc_jp" 
                          :rules="mrValidation.ab_seo_desc_jp" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords JP
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.ab_seo_keywords_jp" />
                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card no-body>
      <template #footer>
        <div class="text-right">
          <b-button class="btn-rounded" variant="transparent"  @click="$router.back()">Cancel</b-button>
          <b-button class="btn-rounded" variant="success" type="submit">Save Changes</b-button>
        </div>
      </template>
    </b-card>
  </b-form>
</validation-observer>
</template>

<script>
import CKEditor from '@/components/CKEditor'
import Datepicker from 'vuejs-datepicker'

export default{
  components:{ CKEditor, Datepicker },
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    // mrCategory:Array,
    linkDetailEN: String,
    linkDetailID: String,
    linkDetailJP: String
  },
  data(){
    return {
      tabIndex: 0,
      tabIndexContent: 0,
      itemsToolbar: [
        'heading',
        '|',
        'sourceEditing',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'uploadImage',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'insertTable','mediaEmbed',
        '|', 
        'outdent', 'indent',
        '|',
        'undo',
        'redo'
      ]
    }
  },
  computed:{
    blogStatus(){
      if(!this.row.ab_publish_date && !this.row.ab_status) return ""
      else if(this.row.ab_status == "D") return "D"
      else if(this.row.ab_status == "N") return "N"
      const dateNow = new Date()
      if(new Date(this.row.ab_publish_date) > dateNow) return "S"
      else return "P"
    }
  },
  methods:{
    watchTitle(lang){
      if(lang == 'id'){
        if(this.isAdd || this.row.ab_have_published != 'Y'){
          this.row.ab_slug_id = this.row.ab_title_id.slugify()
          this.row.ab_seo_canonical_id = this.linkDetailID + this.row.ab_title_id.slugify()
        }
        this.row.ab_seo_title_id = this.row.ab_title_id
      }else if(lang == 'en'){
        if(this.isAdd || this.row.ab_have_published != 'Y'){
          this.row.ab_slug_en = this.row.ab_title_en.slugify()
          this.row.ab_slug_jp = this.row.ab_title_en.slugify()
          this.row.ab_seo_canonical_en = this.linkDetailEN + this.row.ab_title_en.slugify()
          this.row.ab_seo_canonical_jp = this.linkDetailJP + this.row.ab_title_en.slugify()
        }
        this.row.ab_seo_title_en = this.row.ab_title_en
      }else if(lang == 'jp'){
        // SLUG JP diambil dari SLUG EN
        this.row.ab_seo_title_jp = this.row.ab_title_jp
      }
    },
    doSubmitArticle(){
      if(!this.row.ab_title_id || !this.row.ab_content_id || !this.row.ab_tags_id){
        this.tabIndex = 0        
        this.tabIndexContent = 0
      }
      else if(!this.row.ab_title_en || !this.row.ab_content_en || !this.row.ab_tags_en){
        this.tabIndexContent = 1
        this.tabIndex = 0              
      } 
      else if(!this.row.ab_title_jp || !this.row.ab_content_jp || !this.row.ab_tags_jp){
        this.tabIndexContent = 2
        this.tabIndex = 0              
      } 
      else if(!this.row.ab_publish_date || !this.row.ab_author || !this.row.ab_image){
        this.tabIndex = 0        
      }
      else{
        if(!this.row.ab_seo_title_id || !this.row.ab_seo_title_en || !this.row.ab_seo_title_jp || !this.row.ab_seo_keywords_id || !this.row.ab_seo_keywords_en || !this.row.ab_seo_keywords_jp ||!this.row.ab_seo_desc_id || !this.row.ab_seo_desc_en || !this.row.ab_seo_desc_jp){
          this.tabIndex = 1
        }
      }
      
      this.$set(this.row, 'blogStatus', this.blogStatus)
      this.$parent.doSubmitCRUD('VForm', this.$refs)
    }
  },
}
</script>